import React, { useRef, useState } from 'react';
import './Auth.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

// Routers
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import { app } from '../firebase.js';
import { getFirestore } from '@firebase/firestore';

import logo from '../Images/logo.png';

 
import InputGroup from 'react-bootstrap/InputGroup';  

import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut
} from 'firebase/auth';

import { collection, doc, setDoc } from '@firebase/firestore';

function Register() {
  let navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();
  const genderRef = useRef();

  const [showProgress, setShowProgress] = useState(false);
  const [showSignInbtn, setShowSignInbtn] = useState(true);

  async function loginUser() {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const username = nameRef.current.value;
    const phone = phoneRef.current.value;
    const userGender = genderRef.current.value;
    const timestamp = new Date();
    


    setShowProgress(true);
    setShowSignInbtn(false);

    const auth = getAuth();
    const db = getFirestore(app);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Send email verification
      await sendEmailVerification(auth.currentUser);

      // Update user data in the database
      const userID = userCredential.user.uid;
      const newUserRef = doc(db, 'users', userID);
      await setDoc(newUserRef, {
        email: email,
        phone: phone,
        signupdate: timestamp,
        userId: userID,
        usertype: 'therapist',
        registeredVia: 'Web',
        isuserVerified: 'no',
        fullname: username,
        userLocation: 'Kileleshwa, Nairobi, Kenya',
        gender:userGender,
        age:18,
        planType:'Free Plan',
        expiryTime:timestamp
      });

        // Example usage:
        const message = "Hi "+username+ "! Welcome to AmaniTouch! We're excited to have you on board. Start exploring and connect with thousands of clients near you. Let's get started!";
        const phoneNumber = phone; 
        const transRef = "TransactionReference"; 
        
        sendSms(message, phoneNumber, transRef);

        // Log out the user
        await signOut(auth);

        setShowProgress(false);
        setShowSignInbtn(true);

      // Redirect to the dashboard after successful signup
     
      swal({
        title: "Registration Successful!",
        text: "Please check your email for verification link",
        icon: "success",
        button: "Okay",
      }).then((value) => {
        navigate('/login');
      });

    } catch (error) {
      setShowProgress(false);
      setShowSignInbtn(true);

      const errorCode = error.code;
      const errorMessage = error.message;
      swal('Login Error!', '' + errorMessage + '', 'error');
    }
  }
  


  async function sendSms(message, phoneNumber, transRef) {
    const url = 'https://us-central1-pepea-a9eaa.cloudfunctions.net/hellosms/sendsms';
    const requestBody = {
      message,
      phoneNumber,
      transRef,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        // Handle the response as needed
        console.log(responseData);
        return true;
      } else {
        console.error(`HTTP error! Status: ${response.status}`);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }
  


  


  
  return (
    <div className='mainAuth'>
      <div className='AuthRight'>

      <Link to='/'> 
        <img src={logo} alt='' className='logoAuth' />
      </Link>

      
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Full Name</Form.Label>
          <Form.Control type='text' placeholder='Your full name' ref={nameRef} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formGender'>
          <Form.Label>Gender</Form.Label>
          <Form.Select aria-label='Select Gender' ref={genderRef}>
            <option value='Female'>Female</option>
            <option value='Male'>Male</option>
            <option value='Non Binary'>Non-binary</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email address</Form.Label>
          <Form.Control type='email' placeholder='Enter email' ref={emailRef} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control type='number' placeholder='Phone number' ref={phoneRef} />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control type='password' placeholder='Password' ref={passwordRef} />
        </Form.Group>

        {showSignInbtn && (
          <Button variant='primary' type='submit' className='btnLogin' onClick={loginUser}>
            Sign up
          </Button>
        )}

        {showProgress && (
          <Button variant='dark' disabled className='btnLogin'>
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            <span> Signing up...</span>
          </Button>
        )}

        <div className='signuptextCont'>
            <Link to='/login' className='signuptext'>Login to your Account </Link> <h6>|</h6>
            <Link to='/reset-password' className='signuptext'> Reset Password</Link>
        </div>
 
      </div>

      <div className='AuthLeft'>
        <div className='authleftCont'>
          <video
            autoPlay
            muted
            loop
            src='https://res.cloudinary.com/ubunifu/video/upload/v1727119071/6186707-uhd_3840_2160_25fps_g2j9sg.mp4'
          ></video>
          <div className='authLeftOpacity'></div>
        </div>
      </div>
    </div>
  );
}

export default Register;