import React, { useState, useRef } from 'react';
import './Auth.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, setDoc} from "@firebase/firestore"; 
import { doc, updateDoc } from "firebase/firestore";
import { app } from '../firebase.js';
import swal from 'sweetalert';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { faSpa, faDumbbell, faRunning, faLeaf, faHotTub, faHands, faUserInjured, faBaby, faWater, faBullseye, faHandHoldingHeart, faBrain, faFlask, faSeedling, faChair, faHandsHelping, faRainbow, faOm, faRulerCombined, faHeart, faYinYang, faStar, faShoePrints, faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';


function ServicesSetup() {

    const [selected, setSelected] = useState([]);
    const [availability, setAvailability] = useState({});
    const db = getFirestore(app);
    const [selectedServices, setSelectedServices] = useState([]);
    const [fromAmount, setFromAmount] = useState('');
    let navigate = useNavigate(); 

    const services = [
        { name: 'Swedish Massage', icon: faSpa },
        { name: 'Deep Tissue Massage', icon: faDumbbell },
        { name: 'Couples Massage', icon: faHeart },
        { name: 'Shiatsu Massage', icon: faUserInjured },
        { name: 'Tui Na Massage', icon: faHandsHelping },
        { name: 'Lomi Lomi Massage', icon: faRainbow },
        { name: 'Bamboo Massage', icon: faSeedling },
        { name: 'Sports Massage', icon: faRunning },
        { name: 'Cupping Therapy', icon: faFlask },
        { name: 'Aromatherapy Massage', icon: faLeaf },
        { name: 'Ayurvedic Massage', icon: faOm },
        { name: 'Balinese Massage', icon: faUmbrellaBeach },
        { name: 'Rejuvenation Massage', icon: faStar },
        { name: 'Prenatal Massage', icon: faBaby },
        { name: 'Trigger Point Massage', icon: faBullseye },
        { name: 'Craniosacral Therapy', icon: faBrain },
        { name: 'Myofascial Release', icon: faHandHoldingHeart },
        { name: 'Hot Stone Massage', icon: faHotTub },
        { name: 'Acupressure Massage', icon: faYinYang },
        { name: 'Lymphatic Drainage Massage', icon: faWater },
        { name: 'Foot Massage', icon: faShoePrints },
        { name: 'Thai Massage', icon: faHands },
        { name: 'Chair Massage', icon: faChair },
        { name: 'Reflexology', icon: faShoePrints },
        { name: 'Rolfing', icon: faRulerCombined }
    ];
  
    const handleServiceToggle = (service) => {
        if (selectedServices.find(s => s.name === service)) {
            setSelectedServices(selectedServices.filter(s => s.name !== service));
        } else {
            swal({
                title: `Enter Minimum Amount for ${service}`,
                content: {
                    element: "input",
                    attributes: {
                        placeholder: "Minimum Amount",
                        type: "number",
                    },
                },
                buttons: {
                    cancel: "Cancel",
                    confirm: {
                        text: "Submit",
                        closeModal: false,
                    },
                },
            }).then((value) => {
                if (value) {
                    const minAmount = value;
                    setSelectedServices(prev => [...prev, { name: service, minAmount }]);
                    swal("Success!", "Minimum amount added.", "success");
                }
            });
        }
    };



    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
           const uid = user.uid;
           window.handleSubmit = function(){
                const timestamp = new Date();

                const userRef = doc(db, "incallOutcallSchedules", uid); // Use userId as the doc ID
                const data = {
                    selectedServices: selected,
                    availability: availability,
                    userId:uid,
                    signupdate: timestamp
                };
        
                try {
                    setDoc(userRef, data); // Merge to update existing records
                    swal("Success!", "Your availability has been saved.", "success");
                } catch (error) {
                    swal("Error", "Failed to save data: " + error.message, "error");
                }

            }

            window.handleSubmitServices = async () => {
                const timestamp = new Date();
                const userRef = doc(db, "userServices", uid);

                console.log("Selected Services:", selectedServices);

                // Filter out services that have a valid minAmount and convert minAmount to a number
                const validServices = selectedServices.filter(service => {
                    return typeof service.minAmount === 'string' && !isNaN(Number(service.minAmount));
                });
            
                if (validServices.length === 0) {
                    swal("Error", "No valid services selected with a price.", "error");
                    return; // Exit early if no valid services
                }
            
                // Convert minAmount to a number and find the minimum value
                const minPrice = validServices.reduce((min, service) => {
                    const serviceMinAmount = Number(service.minAmount);
                    return serviceMinAmount < min ? serviceMinAmount : min;
                }, Number(validServices[0].minAmount)); // Initial minimum value from the first valid service
            
                console.log("Min Price:", minPrice);

                const data = {
                    selectedServices,
                    fromAmount,
                    userId: uid,
                    signupdate: timestamp,
                };

                try {
                    await setDoc(userRef, data, { merge: true });     
                    //update to user profile the minimum amount to maximum amount
                    updateDoc(doc(db, "users", user.uid), {
                        minPrice: minPrice
                    }).then(()=>{
                        swal("Success!", "Your services have been saved.", "success");
                    })  



                } catch (error) {
                    swal("Error", "Failed to save data: " + error.message, "error");
                }
            };
    
            window.goToNext = function(){
                console.log("hello")
                const timestamp = new Date();
                updateDoc(doc(db, "users", user.uid), {
                    isuserVerified: "yes",
                    verificationDate: timestamp
                }).then(()=>{
                    navigate('/profile');
                })  
              }


        } else { 
        }
    });

    //setDoc(userRef, data, { merge: true }); // Merge to update existing records

    const handleSelection = (service) => {
        let newSelection = [...selected];
        if (selected.includes(service)) {
          newSelection = newSelection.filter(item => item !== service);
          const updatedAvailability = { ...availability };
          delete updatedAvailability[service];
          setAvailability(updatedAvailability);
        } else {
          newSelection.push(service);
        }
        setSelected(newSelection);
        console.log(newSelection);
      };
    
      const handleDaySelection = (service, day) => {
        const serviceAvailability = availability[service] || {};
        const newServiceAvailability = serviceAvailability[day]
          ? { ...serviceAvailability, [day]: null }
          : { ...serviceAvailability, [day]: { from: '', to: '' } };
    
        setAvailability({ ...availability, [service]: newServiceAvailability });
        console.log(`${service} availability:`, newServiceAvailability);
      };
    
      const handleTimeChange = (service, day, type, value) => {
        const serviceAvailability = availability[service];
        const dayAvailability = serviceAvailability[day] || {};
        dayAvailability[type] = value;
    
        setAvailability({
          ...availability,
          [service]: { ...serviceAvailability, [day]: dayAvailability },
        });
    
        console.log(`${service} ${day} ${type} time:`, value);
      };
    
      const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];




  




  return (
    <div className='accSetMain'>
        <div className='accSet1'>
            <h2>Set Up your Services</h2>

            <div className="service-container">

                <div className={`service-box ${selected.includes('incalls') ? 'selected' : ''}`} onClick={() => handleSelection('incalls')}>
                    <FontAwesomeIcon icon={faHouse} size="3x" />
                    <p>Incalls</p>
                </div>
                <div className={`service-box ${selected.includes('outcalls') ? 'selected' : ''}`} onClick={() => handleSelection('outcalls')}>
                    <FontAwesomeIcon icon={faSuitcase} size="3x" />
                    <p>Outcalls</p>
                </div>

            {selected.length > 0 && (
                <div className="availability-container">
                {selected.includes('incalls') && (
                    <div className="availability-box">
                    <h4>Incalls Availability</h4>
                    <div className="days-grid">
                        {daysOfWeek.map((day) => (
                        <div key={day} className="day-container">
                            <div
                            className={`day ${availability.incalls?.[day] ? 'day-selected' : ''}`}
                            onClick={() => handleDaySelection('incalls', day)}
                            >
                            {day}
                            </div>
                            {availability.incalls?.[day] && (
                            <div className="time-inputs">
                                <label>
                                From: <input type="time" value={availability.incalls[day].from} onChange={(e) => handleTimeChange('incalls', day, 'from', e.target.value)} />
                                </label>
                                <label>
                                To: <input type="time" value={availability.incalls[day].to} onChange={(e) => handleTimeChange('incalls', day, 'to', e.target.value)} />
                                </label>
                            </div>
                            )}
                        </div>
                        ))}
                    </div>
                    </div>
                )}

                {selected.includes('outcalls') && (
                    <div className="availability-box">
                    <h4>Outcalls Availability</h4>
                    <div className="days-grid">
                        {daysOfWeek.map((day) => (
                        <div key={day} className="day-container">
                            <div
                            className={`day ${availability.outcalls?.[day] ? 'day-selected' : ''}`}
                            onClick={() => handleDaySelection('outcalls', day)}
                            >
                            {day}
                            </div>
                            {availability.outcalls?.[day] && (
                            <div className="time-inputs">
                                <label>
                                From: <input type="time" value={availability.outcalls[day].from} onChange={(e) => handleTimeChange('outcalls', day, 'from', e.target.value)} />
                                </label>
                                <label>
                                To: <input type="time" value={availability.outcalls[day].to} onChange={(e) => handleTimeChange('outcalls', day, 'to', e.target.value)} />
                                </label>
                            </div>
                            )}
                        </div>
                        ))}
                    </div>
                    </div>
                )}
                </div>
            )}
            </div>

            <Button variant="primary" onClick={window.handleSubmit}>
                    Submit
            </Button>

                <br />


                <div className="setupPage-container">
                    <h2 className="setupPage-header">Setup Your Massage Services</h2>
                    <div className="serviceSelection-grid">
                        {services.map((service) => (
                            <div key={service.name} className="serviceCard" onClick={() => handleServiceToggle(service.name)}>
                                <FontAwesomeIcon icon={service.icon} className="serviceIcon" />
                                <div>{service.name}</div>
                                <input
                                    type="checkbox"
                                    checked={selectedServices.some(s => s.name === service.name)}
                                    readOnly
                                />
                            </div>
                        ))}
                    </div>
                    <Button className="submitButton" onClick={() => window.handleSubmitServices()}>Save</Button>
                </div>


                <Button variant='primary' type='submit' className='btnLogin saveChgsBtn' onClick={window.goToNext}>
                    Next
                </Button>
            


        </div>
        <div className='accSet2'>
        <div className='theOpacity'></div>
                <div className='theOpacitySetup'>
                    <h2>Access Thousands of Clients Nearby!</h2>
                    <p>Join our community of massage therapists and connect with clients actively seeking your services. Whether you're offering incall or outcall options, our platform helps you grow your business and reach more people in your area. Start your journey to success today</p>
                </div> 
        </div>
    </div>
  )
}

export default ServicesSetup