import React, { useState, useRef, useEffect } from 'react';
import samplebg from '../Images/mainbg.jpg'
import sampleProfPic from '../Images/samplethree.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDatabase, get, update, increment,ref, set} from "firebase/database"; // Import Realtime Database functions
import { getStorage, ref as myStorageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { faMapMarkerAlt, faPhone,faTrash, faCalendar, faEye, faCheckCircle, faComment, faVideo, faHome, faBriefcase, faPlayCircle 

} from '@fortawesome/free-solid-svg-icons'
import sampleImage from '../Images/sampleimage.webp'
import sampleImage2 from '../Images/sampletwo.webp'
import sampleImage3 from '../Images/samplethree.jpg'
import sampleImage4 from '../Images/samplefour.jpg'
import { useNavigate, Link } from 'react-router-dom';
import swal from 'sweetalert';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, getDocs,deleteDoc, where, onSnapshot,setDoc,addDoc, orderBy } from "@firebase/firestore"; 
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { doc, updateDoc } from "firebase/firestore";
import { app } from '../firebase.js';
import defaultCoverPhoto from '../Images/bg3.jpg';
import defaultProfilePhoto from '../Images/defprofile.png'
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar'; 
import { useLocation } from "react-router-dom";  
import Skeleton from 'react-loading-skeleton'; // Import skeleton component
import NavBar from '../Components/NavBar'
import NavBarAccount from '../Components/NavBarAccount.js';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
width: '100%',
height: '60vh', 
};
 
const center = {
lat: -1.2921, // Default latitude (Nairobi)
lng: 36.8219, // Default longitude (Nairobi)
};


function ViewUser() {


    const location = useLocation();
    // Extract the userId from the query string
    const userId = location.search.substring(1); // This removes the "?" and gets the userId directly
    // Log the userId to the console
    //console.log("User ID:", userId);



    const [loggedInUser, setloggedInUser] = useState("");
    const [viewPhone, setviewPhone] = useState("");
    const [theuserLocation, setuserLocation] = useState("")

    const [planType, setplanType] = useState('');
    const [expiryTime, setExpiryTime] = useState(null);
    const [isPlanActive, setIsPlanActive] = useState(false);

    const [userProfilePicture, setProfilePicture] = useState(defaultProfilePhoto);  // Default profile picture
    const [userCoverPicture, setCoverPicture] = useState(defaultCoverPhoto);  // Default cover picture
    const db = getFirestore(app);
    let navigate = useNavigate();
    const [aboutMe, setaboutMe] = useState("")
    const [products, setProducts] = useState([]);
    const [showAllProducts, setShowAllProducts] = useState(false); // State to toggle view more
    const [profileViews, setProfileViews] = useState(0); // To hold profile views
    const realtimeDb = getDatabase(app); // Initialize Realtime Database

    const [incallSchedule, setIncallSchedule] = useState([]);
    const [outcallSchedule, setOutcallSchedule] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);

    const [showIncallSchedule, setShowIncallSchedule] = useState(false);
    const [showOutcallSchedule, setShowOutcallSchedule] = useState(false);

    const storage = getStorage();
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertTwo, setShowAlertTwo] = useState(false);
    const [uploadProfileStatus, setuploadProfileStatus] = useState("1");
    const [uploadStatStatus, setuploadStatStatus] = useState("Uploading...0%");
    const [UploadBtn, setUploadBtn] = useState("Upload");


    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const [studentsList, setStudentList] = useState([]);
    const [loading, setLoading] = useState(true); 

    const [mediaModalVisible, setMediaModalVisible] = useState(false);
    const [currentMedia, setCurrentMedia] = useState(null); 


    const [selectedAddress, setSelectedAddress] = useState(''); // Address fetched from Firestore
    const [geocodedCoordinates, setGeocodedCoordinates] = useState(null); // Store geocoded lat/lng
    
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyA297W_3urY4B_E0SbyfM_C_eqy6a6JGrY', // Replace with your Google Maps API Key
    });


    
    const auth = getAuth(); 
    const [isLoggedIn, setIsLoggedIn] = useState(false); // To track user login status
    useEffect(() => {
        // Check user login status when the component is mounted
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is logged in
                setIsLoggedIn(true);
            } else {
                // User is logged out
                setIsLoggedIn(false);
            }
        });
    }, [auth]);

    const toggleIncallSchedule = () => {
        setShowIncallSchedule(!showIncallSchedule); 
    };

    const toggleOutcallSchedule = () => {
        setShowOutcallSchedule(!showOutcallSchedule); 
    };



    const toggleShowAllProducts = () => {
        setShowAllProducts(!showAllProducts);
      };
    
      // Get the products to display, either the first 3 or all, based on the toggle
    const displayedProducts = showAllProducts ? products : products.slice(0, 3);

    useEffect(() => {
            const uid = userId;
            const userQuery = query(collection(db, "users"), where("userId", "==", uid));
                
            // Fetch user data from Firestore
            getDocs(userQuery).then((querySnapshot) => {
                querySnapshot.forEach((docSnapshot) => {
                    const userData = docSnapshot.data();
                    setloggedInUser(userData.fullname);
                    setviewPhone(userData.phone);
                    setuserLocation(userData.userLocation); 
                    setaboutMe(userData.aboutMe)
                    setSelectedAddress(userData.userLocation)
                    setplanType(userData.planType);
                    setExpiryTime(userData.expiryTime);

                    // Check if plan has expired
                    const currentDate = new Date();
                    const expiryDate = new Date(userData.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date

                    if (expiryDate > currentDate) {
                    setIsPlanActive(true);
                    } else {
                    setIsPlanActive(false);
                    }
                    
                    // Check for profile and cover photos in Firebase
                    if (userData.profilePic) {
                        setProfilePicture(userData.profilePic);
                    }
                    if (userData.coverPhoto) {
                        setCoverPicture(userData.coverPhoto);
                    }
                });
                setLoading(false); // Data is fetched, stop loading
            });

            // Fetch or initialize profile views from Realtime Database
            const viewsRef = ref(realtimeDb, `profileViews/${uid}`);

            get(viewsRef).then((snapshot) => {
                if (snapshot.exists()) {
                  // If data exists, get the current value
                  const data = snapshot.val();
                  setProfileViews(data.count || 0);
                } else {
                  // If data doesn't exist, initialize it to 0
                  set(viewsRef, { count: 0 }).then(() => {
                    setProfileViews(0);
                  });
                }
              }).then(() => {
                // Once the profile view is checked, increment only once
                update(viewsRef, {
                  count: increment(1)
                }).then(() => {
                  // After incrementing, fetch the new value to reflect it on the UI
                  get(viewsRef).then((snapshot) => {
                    if (snapshot.exists()) {
                      setProfileViews(snapshot.val().count); // Ensure "count" field exists
                    }
                  });
                });
              }); 
          
            const unsub = onSnapshot(doc(db, "userServices", uid), (doc) => {
                if (doc.exists()) {
                    // Extract the services from the Firestore document
                    const services = doc.data().selectedServices || [];
                    
                    // Map Firestore services to your component's expected structure
                    const formattedServices = services.map(service => ({
                      name: service.name,
                      price: `KES.${service.minAmount}`
                    }));
              
                    // Update the state with the formatted services
                    setProducts(formattedServices); 
                }
            });

            const unsub3 = onSnapshot(doc(db, "incallOutcallSchedules", uid), (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
    
                    // Extract selected services, incalls, and outcalls
                    const services = data.selectedServices || [];
                    const incalls = data.availability?.incalls || {};
                    const outcalls = data.availability?.outcalls || {};
    
                    setSelectedServices(services);
    
                    // Map schedule for each day (Monday to Friday)
                    const formatSchedule = (schedule) => {
                        return Object.keys(schedule).map(day => ({
                            day,
                            from: schedule[day]?.from || 'N/A',
                            to: schedule[day]?.to || 'N/A'
                        }));
                    };
    
                    // Set the schedules for Incalls and Outcalls
                    setIncallSchedule(formatSchedule(incalls));
                    setOutcallSchedule(formatSchedule(outcalls));
                }
            });

            const fetchData = async () => {
                try {
                  const q = query(collection(db, 'uploads'), where("userId", "==", uid), orderBy('timeUploaded', 'desc'));
                  const querySnapshot = await getDocs(q);  
                  let studentsItem = [];
                  querySnapshot.forEach((doc) => {
                    studentsItem.push({ id: doc.id, ...doc.data() });
                  });
                  setStudentList(studentsItem);
                  setLoading(false);
                } catch (error) {
                  console.error('Error fetching data:', error);
                  setLoading(false);
                }
              };
              fetchData(); 

    }, [db, realtimeDb]);

    //view phone number

    function viewPhoneNumber(){
        swal("Phone Number", viewPhone);
    }

    function chatWithUser(){
        swal("Info", "User doesnt support chat", "info", {
            button: "Okay",
          });
    }

    function videoCallWithUser(){
        swal("Info", "User doesnt support video call", "info", {
            button: "Okay",
          });
    }

    function goToEdit(){
        navigate("/edit-profile"); 
    }

 

    // Geocode the address to get latitude and longitude
    useEffect(() => {
        if (selectedAddress) {
        const geocodeAddress = async () => {
            const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(selectedAddress)}&key=AIzaSyA297W_3urY4B_E0SbyfM_C_eqy6a6JGrY`
            );
            const data = await response.json(); 
            if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setGeocodedCoordinates({ lat, lng });
            }
        };
        geocodeAddress();
        }
    }, [selectedAddress]);

    if (loadError) return 'Error loading maps';
    if (!isLoaded) return '';



  return (
    <div>
        <div>
            {/* If the user is logged in, show NavBarAccount, otherwise show NavBar */}
            {isLoggedIn ? <NavBarAccount /> : <NavBar />}
        </div>
        <section className='profPage'> 

            <div className='profTopCover clearfix'>
                <div className='profileHeader'>
                {loading ? (
                            <Skeleton height="100%" />
                        ) : (
                            <img src={userCoverPicture} alt="Cover" />
                        )}
                </div>

                <div className='viewProfPicCont'>
                        {loading ? (
                            <Skeleton circle={true} height="100%" width="100%" />
                        ) : (
                            <img src={userProfilePicture} alt="Profile" />
                        )}
                </div>

                <div className='profNameCont'>
                    <div>
                    {loading ? (
                            <Skeleton width={200} height={30} />
                        ) : (
                            <h3>{loggedInUser} 
                            {(isPlanActive && (planType === 'Standard Plan' || planType === 'Premium Plan')) && (
                                <FontAwesomeIcon icon={faCheckCircle} className="verifiedBtn" />
                            )}  
                            </h3>
                        )}
                        {loading ? (
                            <Skeleton width={150} />
                        ) : (
                            <p><FontAwesomeIcon icon={faMapMarkerAlt} className='mapIcon' /> {theuserLocation}</p>
                        )}
                    </div>
                    <div className='userProfileContactCentre'> 
                        <div className='chatButton' onClick={viewPhoneNumber}>
                            <FontAwesomeIcon icon={faPhone} />
                        </div>
                        {/* <div className='chatButton' onClick={chatWithUser}>
                            <FontAwesomeIcon icon={faComment} />
                        </div> */}
                        <div className='chatButton' onClick={videoCallWithUser}>
                            <FontAwesomeIcon icon={faVideo} /> 
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className='profBottomCover'>

                {loading ? (
                <Skeleton height="200px" width="300px" />
                        ) : (
                <div className='profBottomOne'>

                    <p className='profileBio'>{aboutMe}</p>
                    <hr />

                    {/* If selected service is incalls */}
                    {selectedServices.includes('incalls') && (
                    <>
                    <p className='inoutCalls'>
                        <FontAwesomeIcon icon={faHome} className='callIcon' /> Incalls available
                    </p>
                    <p className='serviceTime' onClick={toggleIncallSchedule} style={{ cursor: 'pointer', color: 'royalblue' }}>
                        {showIncallSchedule ? "Hide Schedule" : "View Schedule"}
                    </p> 

                    {/* Only show schedule when clicked */}
                    {showIncallSchedule && (
                        <ul>
                            {incallSchedule.length > 0 ? (
                                incallSchedule.map((schedule, index) => (
                                    <li key={index} className="serviceTime">
                                        {schedule.day}: {schedule.from} - {schedule.to}
                                    </li>
                                ))
                            ) : (
                                <li className='serviceTime'>No incalls available</li>
                            )}
                        </ul>
                    )}
                </>
            )}

            {/* If selected service is outcalls */}
            {selectedServices.includes('outcalls') && (
                <>
                    <p className='inoutCalls2'>
                        <FontAwesomeIcon icon={faBriefcase} className='callIcon' /> Outcalls available
                    </p>
                    <p className='serviceTime' onClick={toggleOutcallSchedule} style={{ cursor: 'pointer', color: 'royalblue' }}>
                        {showOutcallSchedule ? "Hide Schedule" : "View Schedule"}
                    </p>

                    {/* Only show schedule when clicked */}
                    {showOutcallSchedule && (
                        <ul>
                            {outcallSchedule.length > 0 ? (
                                outcallSchedule.map((schedule, index) => (
                                    <li key={index} className="serviceTime">
                                        {schedule.day}: {schedule.from} - {schedule.to}
                                    </li>
                                ))
                            ) : (
                                <li className='serviceTime'>No outcalls available</li>
                            )}
                        </ul>
                    )}
                </>
            )}



                    <hr/>

                    <p className='eyeIcon'><FontAwesomeIcon icon={faEye} /> {profileViews} profile visits</p>
                    <hr />

                    <ul>
                        {displayedProducts.map((product, index) => (
                        <li key={index} className="productItem">
                            <div className="productDetails">
                            <span className="productName">{product.name}</span>
                            <span className="productPrice"><span className='fromSpan'>from</span> {product.price}</span>
                            </div>
                        </li>
                        ))}
                    </ul>

                    {/* Conditionally show "View more services" */}
                    {products.length > 3 && (
                        <p onClick={toggleShowAllProducts} className="viewMoreServices">
                        {showAllProducts ? 'Show less services' : 'View more services'}
                        </p>
                    )}


                </div>
                )}
                <div className='profBottomTwo2'>


                    {studentsList.map((mystudent, index) => (
                    <div key={mystudent.id} className='servicePoviderListing2'>
                        
                        <div className='serviceProvLink' onClick={() => { 
                            setCurrentMedia(mystudent); 
                            setMediaModalVisible(true); 
                        }}>  
                            <div className='serviceProvImageCont2'>
                                {mystudent.fileType.includes('image') ? (
                                    <img src={mystudent.mediaUrl} alt="Uploaded Media" />
                                ) : mystudent.fileType.includes('video') ? (
                                    <div className="video-container">
                                        <video className="video-thumbnail">
                                            <source src={mystudent.mediaUrl} type={mystudent.fileType} />
                                            Your browser does not support the video tag.
                                        </video>
                                        <div className="play-button-overlay">
                                            <FontAwesomeIcon icon={faPlayCircle} className="play-icon" />
                                        </div>
                                    </div>
                                ) : (
                                    <p>Unsupported media type</p>
                                )}
                            </div>
                        </div>
                    </div>
                ))}




                       
                </div>
            </div>
        </section>

        {geocodedCoordinates ? (
       <GoogleMap
       mapContainerStyle={mapContainerStyle}
       zoom={16}
       center={geocodedCoordinates}
     >
       <Marker
         position={geocodedCoordinates}
         icon={{
           url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png', // Custom marker icon URL
           size: new window.google.maps.Size(50, 50), // Original size of the icon
           scaledSize: new window.google.maps.Size(50, 50), // Scaled size of the icon
           anchor: new window.google.maps.Point(25, 25), // Anchor the marker at its center
         }}
       />
     </GoogleMap>
      
      ) : (
        <p></p>
      )}


        {/* Media Modal */}
        <div className={`modal myModalMob fade ${mediaModalVisible ? 'show d-block' : 'd-none'}`} 
            tabIndex="-1" 
            role="dialog" 
            aria-hidden="true" 
            style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Media Viewer</h5>
                        <button 
                            type="button" 
                            className="close" 
                            onClick={() => setMediaModalVisible(false)}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {currentMedia && currentMedia.fileType.includes('video') ? (
                            <video controls className="w-100">
                                <source src={currentMedia.mediaUrl} type={currentMedia.fileType} />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            currentMedia && <img src={currentMedia.mediaUrl} alt="Uploaded" className="w-100" />
                        )}
                    </div>
                </div>
            </div>
        </div>


    </div>
  )
}
 

export default ViewUser