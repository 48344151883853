import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom'; // For navigation
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { app } from '../firebase.js';
import axios from 'axios';
import swal from 'sweetalert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'; 
import { getFirestore, collection, query, getDocs, where,Timestamp, doc, updateDoc, setDoc } from "@firebase/firestore"; 


function UpgradeAccount() {

    const auth = getAuth();
    const db = getFirestore(app);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate(); // Hook for navigation

    const [selectedPayment, setSelectedPayment] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [formattedPhone, setFormattedPhone] = useState('');
    const [showPayment, setShowPayment] = useState(false); // Control visibility of payment container
    const [showPaymentContainer, setShowPaymentContainer] = useState(false); // Control payment-container visibility
    const [finalAmount, setFinalAmount] = useState('0')
    const [thePaymentCycle, setThePaymentCycle] = useState('')
    const [finalSelectedPackage, setFinalSelectedPackage] = useState('');
    const [loading, setLoading] = useState(false); // New loading state
    const [loggedInUser, setloggedInUser] = useState(""); 
    const [loggedInPhoneNumber, setloggedInPhoneNumber] = useState(""); 
    
   
    const handleDismissPayment = () => {
        setShowPayment(false);
        setShowPaymentContainer(false)
    };
    
    // Function to show payment container from pricing plan
    const handleShowPayment = () => {
        setShowPayment(true);
    };


    const handlePaymentSelect = (paymentType) => {
        setSelectedPayment(paymentType);
        
    };

    // Function to format phone number
    const handlePhoneNumber = (e) => {
        let rawNumber = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
        if (rawNumber.startsWith('0')) {
            rawNumber = '254' + rawNumber.substring(1);
        } else if (rawNumber.startsWith('+254')) {
            rawNumber = rawNumber.replace('+', '');
        }
        setPhoneNumber(e.target.value); // Original input for user feedback
        setFormattedPhone(rawNumber); // The actual formatted number
    };


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
                const uid = user.uid;
                const userQuery = query(collection(db, "users"), where("userId", "==", uid));

                getDocs(userQuery).then((querySnapshot) => {
                    querySnapshot.forEach((docSnapshot) => {
                        const userData = docSnapshot.data();
                        setloggedInUser(userData.fullname);
                        setloggedInPhoneNumber(userData.phoneNumber);
                    });
                });

            } else {
                setIsLoggedIn(false);
            }
        });
    }, [auth]);

    const [billingCycle, setBillingCycle] = useState('monthly');

    const handleToggle = () => {
        setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
    };

    const getPrice = (monthlyPrice, yearlyPrice) => {
        return billingCycle === 'monthly' ? monthlyPrice : yearlyPrice;
    };

    function generateTransactionRef(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
    
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
    
        return result;
    }

    // Handle package selection
    const handlePackageSelect = (packageName, price) => {
        console.log(`Package selected: ${packageName}`);
        console.log(`Billing cycle: ${billingCycle}`);
        console.log(`Price: KES ${price}`);

        if (!isLoggedIn) {
            // If the user is not signed in, navigate to sign-up page
           // navigate('/signup');
        } else {
            // Proceed to checkout or relevant action 
            setShowPayment(true); 
            setShowPaymentContainer(true); // Show payment container when a package is selected  
            console.log(`Proceeding with ${packageName} package for logged in user`);
            const thefinalPricing = price.toString()
            setFinalAmount("1");
            setFinalSelectedPackage(packageName)
            setThePaymentCycle(billingCycle)

            //  
            
        }
    };



    // Submit phone number for M-Pesa
    const handleSubmitMpesa = () => {
        if (formattedPhone.length === 12) {
            //console.log(`Processing payment with phone number: ${formattedPhone}`);
            setLoading(true); // Show the spinner when payment starts
            sendStkPush(formattedPhone, finalAmount);
            
        } else {
            swal('Error!', 'Please enter a valid phone number', 'info');
        }
    }; 

      
  const sendStkPush = async (phone, amount) => {
    try {
      const response = await axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/api/stkpush', {
        phone,
        amount
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      console.log('STK Push Response:', data);

      if (data.ResponseCode === '0') {
        // STK Push sent successfully
        swal({
          title: 'STK Push Sent',
          text: data.CustomerMessage,
          icon: 'success',
          buttons: {
            confirm: {
              text: 'I have paid',
              value: 'confirm',
              className: 'confirm-button'
            },
            cancel: 'I haven\'t paid'
          },
          content: {
            element: 'div',
            attributes: {
              innerHTML: '<style>.confirm-button { position: relative; }</style>'
            }
          }
        }).then((value) => {
          if (value === 'confirm') {
            // setIsLoading(true); // Show loading spinner on the "I have paid" button

              verifyPayment(data.CheckoutRequestID).then(() => {
              //setIsLoading(false); // Hide loading spinner after payment confirmation
            });

          } else {
            swal('Please complete the payment and try again.');
          }
        });
      } else {
        swal('Failed to send STK Push Request');
      }
    } catch (error) {
      console.error('Error sending STK Push request:', error);
      swal('Error sending STK Push Request. Please try again.');
    }
  };


  const verifyPayment = async (queryCode) => {
    try {
      const response = await axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/api/query', {
        queryCode
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      console.log('Payment Query Response:', data);

      if (data.ResultCode === '0') {
        // Payment verified successfully, proceed to save to Firebase
        const currentDate = Timestamp.now();
        const transactionRef = generateTransactionRef(7);
    
        const newDataRef = doc(collection(db, "Payments"));
        setDoc(newDataRef, {
            CheckoutRequestID: data.CheckoutRequestID,
            amountPaid: finalAmount,
            status: 'Paid',
            paymentMethod: 'M-Pesa',
            phoneNumber: phoneNumber,
            selectedPackage: finalSelectedPackage,
            thePaymentCycle: thePaymentCycle,
            timestamp: currentDate,
            transactionRef,
            userId: auth.currentUser.uid,
            documentId: newDataRef.id
        }).then(async () => {
            const userid = auth.currentUser.uid;
            let newExpiryDate;
    
            // Determine the expiry date based on the payment cycle
            if (thePaymentCycle === 'monthly') {
                // Add 1 month to the current date
                let currentDateObj = new Date(); // Convert Timestamp to Date
                currentDateObj.setMonth(currentDateObj.getMonth() + 1); // Add one month
                newExpiryDate = Timestamp.fromDate(currentDateObj); // Convert back to Timestamp
            } else if (thePaymentCycle === 'yearly') {
                // Add 12 months to the current date
                let currentDateObj = new Date(); // Convert Timestamp to Date
                currentDateObj.setFullYear(currentDateObj.getFullYear() + 1); // Add one year
                newExpiryDate = Timestamp.fromDate(currentDateObj); // Convert back to Timestamp
            }
    
            // Update the user's planType, paymentCycle, and expiryTime in Firestore
            await updateDoc(doc(db, "users", userid), {
                planType: finalSelectedPackage,
                upgradeTime: currentDate,
                paymentCycle: thePaymentCycle,
                expiryTime: newExpiryDate
            });
    
            // Send SMS to user
            const smsMessage = `Dear ${loggedInUser}, your account has been successfully upgraded to ${finalSelectedPackage}! Enjoy the enhanced features and benefits. If you have any questions, please contact us. Thank you for being a valued member of AmaniTouch!`;
            const smsSent = sendSms(smsMessage, loggedInPhoneNumber, transactionRef);
    
            if (smsSent) {
                swal('Account Upgraded!', 'Your account has been successfully upgraded!', 'success').then(() => {
                    navigate('/profile');
                });
            } else {
                swal('Account Upgraded!', 'Your account has been successfully upgraded!', 'success').then(() => {
                    navigate('/profile');
                });
            }    
        });
    } else {
        swal('Payment verification failed. Please try again.');
        setLoading(false); // Show the spinner when payment starts
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      swal('Error verifying payment. Please try again.');
      setLoading(false); // Show the spinner when payment starts
    }
  };


  const sendSms = async (message, phoneNumber, transRef) => {
    const url = 'https://us-central1-pepea-a9eaa.cloudfunctions.net/hellosms/sendsms';
    const requestBody = {
      message,
      phoneNumber,
      transRef,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        return true;
      } else {
        console.error(`HTTP error! Status: ${response.status}`);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

    

    return ( 
        <div>
            {isLoggedIn ? <NavBarAccount /> : <NavBar />}

            {!showPaymentContainer && (
            <div className="pricing-plan">
                <h1 className="pricing-title">Choose Your Package</h1>
                <p className="pricing-description">Select a plan that fits your needs and unlock the benefits of AmaniTouch!</p>

                {/* Toggle between Monthly and Yearly billing */}
                <div className="billing-toggle">
                    <span className={billingCycle === 'monthly' ? 'active' : ''}>Monthly</span>
                    <div className="toggle-switch" onClick={handleToggle}>
                        <div className={`toggle-circle ${billingCycle}`}></div>
                    </div>
                    <span className={billingCycle === 'yearly' ? 'active' : ''}>Yearly</span>
                </div>

                <div className="pricing-packages">
                    <div className="pricing-package">
                        <h2 className="package-title">Free Plan</h2>
                        <h3 className="package-price">KES 0</h3>
                        <ul className="package-features">
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Access to basic features</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Profile setup</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Limited client access</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Upload up to 3 images or videos</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Display up to 3 massage services</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> No access to premium client features</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Limited visibility in search results</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Up to 500 unique profile views per month</li>
                        </ul>
                        <button className="select-button" onClick={() => handlePackageSelect('Free Plan', 0)}>Get Started for free</button>
                    </div>

                    <div className="pricing-package">
                        <h2 className="package-title">Standard Plan</h2>
                        <h3 className="package-price">KES {getPrice(950, 950 * 12 * 0.8)}</h3>
                        <ul className="package-features">
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> All Free Plan features included</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Verified Badge on your profile</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Upload unlimited images and videos</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Showcase all your massage services (no limit)</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Priority in search results</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Booking management tools (automated confirmations)</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Clients can book and pay 30% deposit upfront</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> In-app messaging with clients</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Video calls with clients</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Analytics dashboard for service performance</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Enhanced profile customization options</li>
                        </ul>
                        <button className="select-button" onClick={() => handlePackageSelect('Standard Plan', getPrice(950, 950 * 12 * 0.8))}>Get Started</button>
                    </div>

                    <div className="pricing-package">
                        <h2 className="package-title">Premium Plan</h2>
                        <h3 className="package-price">KES {getPrice(3950, 3950 * 12 * 0.8)}</h3>
                        <ul className="package-features">
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> All Free Plan features included</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Verified Badge on your profile</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Upload unlimited images and videos</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Showcase all your massage services (no limit)</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Priority in search results</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Booking management tools (automated confirmations)</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Clients can book and pay 30% deposit upfront</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> In-app messaging with clients</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Video calls with clients</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Analytics dashboard for service performance</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Enhanced profile customization options</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Access to subscribed client members</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Access to premium clients with recurring bookings</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Premium promotion on the platform (higher visibility)</li>
                            <li className="feature-item"><FontAwesomeIcon icon={faCheck} className="upgradecheck" /> Inclusion in exclusive promotions and marketing efforts</li>
                        </ul>
                        <button className="select-button" onClick={() => handlePackageSelect('Premium Plan', getPrice(3950, 3950 * 12 * 0.8))}>Get Started</button>
                    </div>
                </div>
            </div>
            )}

            {showPayment && (

<div className={`payment-container ${showPayment ? '' : 'hidden'}`}>
<button className="dismiss-button" onClick={handleDismissPayment}>✕</button>


<div className="order-summary-container">
    <h2 className="order-summary-title">Order Summary</h2>
    <div className="order-summary-details">
        <div className="order-item">
            <span className="item-label">Package:</span>
            <span className="item-value">{finalSelectedPackage}</span>
        </div>
        <div className="order-item">
            <span className="item-label">Billing Cycle:</span>
            <span className="item-value">{billingCycle.charAt(0).toUpperCase() + billingCycle.slice(1)}</span>
        </div>
        <div className="order-item">
            <span className="item-label">Total Amount:</span>
            <span className="item-value">KES {finalAmount}</span>
        </div>
    </div>
</div>

<div className="payment-header">
    <h1 className="payment-title">Select Your Payment Method</h1>
    <p className="payment-description">Complete your transaction with one of the following payment options.</p>
</div>

<div className="payment-options">
    <button
        className={`payment-option ${selectedPayment === 'mpesa' ? 'selected' : ''}`}
        onClick={() => handlePaymentSelect('mpesa')}
    >
        <span className="payment-icon">📱</span> Pay with M-Pesa
    </button>

    <button
        className={`payment-option ${selectedPayment === 'card' ? 'selected' : ''}`}
        onClick={() => handlePaymentSelect('card')}
    >
        <span className="payment-icon">💳</span> Pay with Card
    </button>
</div>

{/* Card Payment */}
{selectedPayment === 'card' && (
    <div className="payment-card">
        <h2 className="payment-section-title">Card Payment</h2>
        <div className="card-details">
            <p>Payment method not supported in your region</p>
            
        </div>
    </div>
)}

{/* M-Pesa Payment */}
{selectedPayment === 'mpesa' && (
    <div className="payment-mpesa">
        <h2 className="payment-section-title">M-Pesa Payment</h2>

        <p>Enter your phone number belowe to receive a payment prompt</p>

        <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Enter your phone number</Form.Label> 
            <Form.Control type="number" placeholder="e.g. 0712345678" value={phoneNumber} onChange={handlePhoneNumber}/> 
        </Form.Group>

        {loading ? (
            <Button disabled className="submit-button w-100">
                 <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                 <span> Processing Payment...</span>
               </Button>
            ) : (
            <Button onClick={handleSubmitMpesa} className="submit-button w-100">Make Payment</Button>
        )}
    </div>
)}
</div>

         )}

            <Footer />
        </div>
    );
}

export default UpgradeAccount;
