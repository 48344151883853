import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';
import normalImage from '../Images/subjectOne.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faUserCheck, faCalendarAlt, faSpa, faHands, faLeaf, faUserTie, faHome, faHandHoldingUsd, faCogs, faShieldAlt, faLock, faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';


function AboutUs() {

  const auth = getAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, [auth]);

  return (
    <div>
      {/* NavBar based on login status */}
      {isLoggedIn ? <NavBarAccount /> : <NavBar />}

      {/* Top Bar Section */}
      <div className='aboutTopBar'>
        <div className='funnyOpacityCover'></div>
        <div className='aboutTopBarCont'>
          <h1 className="pageTitle">About Us</h1>
          <p>Your Trusted Platform for Professional Massage Therapy</p>
        </div>
      </div>


      {/* About Us Section */}
      <section className="about-section">
        <div className="container">
          <div className="row about3647">
            <div className="col-lg-6">
              <img src={normalImage} alt="Massage Therapy" className="img-fluid" />
            </div>
            <div className="col-lg-6">
              <h2>Welcome to AmaniTouch</h2>
            <p>Welcome to AmaniTouch the leading platform connecting professional massage therapists with clients seeking high-quality, personalized services. Whether you're a therapist looking to expand your client base or an individual in search of relaxation and therapeutic solutions, we've got you covered. <br /> <br />
            At AmaniTouch we believe in the transformative power of touch. We understand that massage therapy is more than just a luxury—it’s a path to physical, emotional, and mental well-being. That’s why our mission is to make it easy for clients to find qualified massage therapists in their area, offering a wide range of services tailored to meet individual needs.
            </p>
            </div>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="mission-section">
        <div className="container">
          <h2>Our Mission</h2>
          <p>
            Our mission is simple: to empower both therapists and clients by creating a seamless platform that fosters
            connections, builds trust, and elevates the standard of care in the massage industry.
          </p>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <FontAwesomeIcon icon={faTools} size="3x" />
                <h3>Tools for Growth</h3>
                <p>Providing therapists with the tools to grow their business.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <FontAwesomeIcon icon={faUserCheck} size="3x" />
                <h3>Access to Therapists</h3>
                <p>Giving clients access to thousands of certified massage professionals near them.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <FontAwesomeIcon icon={faCalendarAlt} size="3x" />
                <h3>Seamless Booking</h3>
                <p>Ensuring a safe, easy, and convenient booking experience for everyone.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* For Clients Section */}
      <section className="clients-section">
        <div className="container">
          <h2>For Clients</h2>
          <p>
            Whether you're looking to unwind after a long day, address chronic pain, or simply pamper yourself, we make
            it easy for you to find the perfect therapist. We feature a wide variety of services designed to meet your
            unique needs.
          </p>
          <div className="features-grid">
            <div className="feature-item">
              <FontAwesomeIcon icon={faSpa} size="3x" />
              <h3>Swedish Massage</h3>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faHands} size="3x" />
              <h3>Deep Tissue Massage</h3>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faLeaf} size="3x" />
              <h3>Aromatherapy</h3>
            </div>
          </div>
        </div>
      </section>

      {/* For Therapists Section */}
      <section className="therapists-section">
        <div className="container">
          <h2>For Therapists</h2>
          <p>
            As a massage therapist, you’re more than just a service provider—you’re a healer. We understand how
            challenging it can be to manage a thriving practice, so we offer tools to help you manage bookings, payments,
            and clients all in one place.
          </p>
          <div className="row">
            <div className="col-md-6">
              <ul className="list-features">
                <li><FontAwesomeIcon icon={faUserTie} /> Promote your services with an in-depth profile.</li>
                <li><FontAwesomeIcon icon={faHome} /> Offer both incall and outcall services for flexibility.</li>
                <li><FontAwesomeIcon icon={faHandHoldingUsd} /> Manage bookings, payments, and services with ease.</li>
              </ul>
            </div>
            
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-us-section">
        <div className="container text-center">
          <h2>Why Choose Us?</h2>
          <div className="row">
            <div className="col-md-3">
              <div className="icon-box">
                <FontAwesomeIcon icon={faCogs} size="3x" />
                <h4>Convenience</h4>
                <p>Our platform is user-friendly for both clients and therapists.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="icon-box">
                <FontAwesomeIcon icon={faShieldAlt} size="3x" />
                <h4>Trusted</h4>
                <p>We vet all therapists and feature real-time reviews.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="icon-box">
                <FontAwesomeIcon icon={faLock} size="3x" />
                <h4>Safe & Secure</h4>
                <p>We prioritize secure transactions and therapist verification.</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="icon-box">
                <FontAwesomeIcon icon={faHandHoldingHeart} size="3x" />
                <h4>Diverse Services</h4>
                <p>We offer various therapies, from relaxation to pain relief.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="vision-section">
        <div className="container text-center">
          <h2>Our Vision</h2>
          <p>
            We envision a world where massage therapy is accessible and a regular part of everyone’s wellness routine.
            We aim to build a community where therapists and clients can connect and thrive.
          </p>
        </div>
      </section>  

      <Footer />
    </div>
  );
}

export default AboutUs;
