import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';

function CommunityGuidelines() {

    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      });
    }, [auth]);

  return (
    <div>
        {isLoggedIn ? <NavBarAccount /> : <NavBar />}
        <div className="community-guidelines">
      <h1>Community Guidelines</h1>
      
      <section className="respect-safety">
        <h2>1. Respect and Safety</h2>
        <p>
          At AmaniTouch, we are committed to creating a respectful and safe community for both clients and therapists. Everyone must treat each other with dignity and kindness. Disrespectful behavior, harassment, or discrimination will not be tolerated.
        </p>
        <ul>
          <li>Be respectful in all communications.</li>
          <li>Harassment, threats, or inappropriate comments are strictly prohibited.</li>
          <li>Respect others' privacy and confidentiality at all times.</li>
        </ul>
      </section>

      <section className="professionalism">
        <h2>2. Professionalism</h2>
        <p>
          Therapists are expected to maintain high standards of professionalism in all interactions with clients. This includes being punctual, providing services as described, and adhering to ethical conduct.
        </p>
        <ul>
          <li>Provide honest, professional services as advertised.</li>
          <li>Keep appointments and communicate in advance if changes are needed.</li>
          <li>Maintain proper hygiene and ensure your workspace is clean and safe.</li>
        </ul>
      </section>

      <section className="client-conduct">
        <h2>3. Client Conduct</h2>
        <p>
          Clients should respect the therapist’s professional boundaries and follow the platform’s guidelines for fair and reasonable expectations.
        </p>
        <ul>
          <li>Respect the therapist’s time by showing up for scheduled appointments.</li>
          <li>Avoid inappropriate behavior or requests.</li>
          <li>Provide feedback constructively and responsibly.</li>
        </ul>
      </section>

      <section className="fair-use">
        <h2>4. Fair Use of the Platform</h2>
        <p>
          AmaniTouch is designed to connect clients and therapists for legitimate massage therapy services. Misusing the platform for purposes other than its intended use is strictly prohibited.
        </p>
        <ul>
          <li>Only use AmaniTouch to book or offer legitimate massage therapy services.</li>
          <li>Do not use the platform to solicit inappropriate or illegal services.</li>
          <li>Report any violations of these guidelines to the support team.</li>
        </ul>
      </section>

      <section className="no-discrimination">
        <h2>5. No Discrimination</h2>
        <p>
          We have a zero-tolerance policy for any form of discrimination, including but not limited to race, gender, ethnicity, religion, or sexual orientation. Everyone deserves to be treated with equal respect.
        </p>
        <ul>
          <li>Ensure that all interactions are free from discriminatory behavior.</li>
          <li>AmaniTouch is a safe space for all users, regardless of background.</li>
        </ul>
      </section>

      <section className="conflict-resolution">
        <h2>6. Conflict Resolution</h2>
        <p>
          If conflicts or disagreements arise, we encourage open, respectful communication to resolve issues. If further assistance is needed, our support team is available to help mediate.
        </p>
        <ul>
          <li>Communicate clearly and respectfully when resolving conflicts.</li>
          <li>Contact support if you're unable to resolve an issue independently.</li>
        </ul>
      </section>

      <section className="accountability">
        <h2>7. Accountability</h2>
        <p>
          Both clients and therapists are responsible for adhering to these community guidelines. Failure to do so may result in suspension or removal from the platform.
        </p>
        <ul>
          <li>Respect all users and follow platform policies.</li>
          <li>Any violations of these guidelines will be taken seriously and could lead to penalties.</li>
        </ul>
      </section>

      <section className="report-issues">
        <h2>8. Reporting Issues</h2>
        <p>
          If you witness or experience behavior that violates these guidelines, we encourage you to report it immediately. Your report will be handled confidentially, and we’ll take the necessary actions to ensure the safety of the community.
        </p>
        <ul>
          <li>Use the “Report” feature on the platform to notify us of issues.</li>
          <li>We prioritize the safety and well-being of all users and will investigate reports thoroughly.</li>
        </ul>
      </section>

      
    </div>

        <Footer />
    </div>
  )
}

export default CommunityGuidelines