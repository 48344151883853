import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NavBar from '../Components/NavBar';
import NavBarAccount from '../Components/NavBarAccount';
import Footer from '../Components/Footer';

function NewsUpdates() {

    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      });
    }, [auth]);

  return (
    <div>
        {isLoggedIn ? <NavBarAccount /> : <NavBar />}

        <div className='aboutTopBar'>
            <div className='funnyOpacityCover'></div>
            <div className='aboutTopBarCont'>
            <h1 className="pageTitle">News and Updates</h1>
            <p>Your Trusted Platform for Professional Massage Therapy</p>
            </div>
        </div>
        <section className="account-management">
            <p>No updates..</p>

            
        </section>
        <Footer />
    </div>
  )
}

export default NewsUpdates