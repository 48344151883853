import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

 
function Footer() {
  return (
    <footer className='footerContainer'>

      <div className='heardEnough'>
        <h6>HEARD ENOUGH? <FontAwesomeIcon icon={faArrowRight} /></h6>
        <h1>Get started</h1>
        <Link to='/signup'> 
            <div className='getStartediconFooter'>
            <FontAwesomeIcon icon={faArrowRight} />
            </div>
        </Link>
      </div>

      <div className='bottomFooter'>
        <div className='footerColumn'>
          <h2>About Us</h2>
          <p>
            Welcome to AmaniTouch, the platform connecting clients with certified massage therapists for personalized care. Whether you need relaxation or specialized therapy, we offer a seamless, secure experience. Therapists can grow their business, manage bookings, and reach new clients. Discover the power of massage with us.
          </p>
        </div>

        <div className='footerColumn'>  
        <h2>Quick Links</h2>
        <ul>
            <li><Link className='QuickLinks' to='/#'>Home</Link></li>
            <li><Link className='QuickLinks' to='/about-us'>About Us</Link></li>
            <li><Link className='QuickLinks' to='/signup '>Become a Therapist</Link></li>
            <li><Link className='QuickLinks' to='/get-verified'>Get Verified</Link></li>
            <li><Link className='QuickLinks' to='/help-centre'>Help Centre</Link></li>
            <li><Link className='QuickLinks' to='/news-and-updates'>News and Updates</Link></li>
        </ul>
        </div>

        <div className='footerColumn'>
        <h2>Quick Links</h2>
        <ul>
            <li><Link className='QuickLinks' to='/community-guidelines'>Community Guidelines</Link></li>
            <li><Link className='QuickLinks' to='/terms-of-service'>Terms of Service</Link></li>
            <li><Link className='QuickLinks' to='/privacy-policy'>Privacy Policy</Link></li>   
            <li><Link className='QuickLinks' to='/faqs'>FAQs</Link></li>
            <li><Link className='QuickLinks' to='/safety-and-security'>Safety & Security</Link></li>
            <li><Link className='QuickLinks' to='/refund-policy'>Refund Policy</Link></li>
        </ul>
        </div>



        <div className='footerColumn footertwoP'>
          <h2>Contact Us</h2>
          <p><FontAwesomeIcon icon={faPhone} /> +44 123 456 789</p>
          <p><FontAwesomeIcon icon={faEnvelope} /> info@amanitouch.app</p>
          <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Edinburgh, UK</p>
          <p>&copy; 2024. All rights reserved.</p>
          <div className='socialIcons'>
            <FontAwesomeIcon icon={faInstagram} className='thesocialIcons' />
            <FontAwesomeIcon icon={faTiktok} className='thesocialIcons' /> 
            <FontAwesomeIcon icon={faFacebook} className='thesocialIcons' />
            <FontAwesomeIcon icon={faTwitter} className='thesocialIcons'/> 
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;