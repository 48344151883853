import React, { useState, useRef, useEffect } from 'react';
import './Auth.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, getDocs, where } from "@firebase/firestore"; 
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { app } from '../firebase.js';
import defaultCoverPhoto from '../Images/bg3.jpg';
import defaultProfilePhoto from '../Images/defprofile.png'
import swal from 'sweetalert';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
import toastr from 'toastr';


function AccountSetuPul() {

    const [fromLocation, setFromLocation] = useState({ lat: 0, lng: 0 });
    const [fromAddress, setFromAddress] = useState('');  // Default location
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);
    const [loggedInUser, setloggedInUser] = useState("");
    const [viewPhone, setviewPhone] = useState("");

    const fileInput = useRef();
    const coverInput = useRef();

    const [userProfilePicture, setProfilePicture] = useState(defaultProfilePhoto);  // Default profile picture
    const [userCoverPicture, setCoverPicture] = useState(defaultCoverPhoto);  // Default cover picture
    
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertTwo, setShowAlertTwo] = useState(false);
    const [UploadBtn, setUploadBtn] = useState("Upload");
    const [UploadBtn2, setUploadBtn2] = useState("Upload");
    const [uploadProfileStatus, setuploadProfileStatus] = useState("1");
    const [uploadStatStatus, setuploadStatStatus] = useState("Uploading...0%");
    const [uploadStatStatus2, setuploadStatStatus2] = useState("Uploading...0%");
    const [theuserLocation, setuserLocation] = useState("Kileleshwa, Nairobi, Kenya")
    const [ageInfo, setAgeInfo] = useState('');

    const [aboutMe, setaboutMe] = useState("")

    //lets get the references here
    const phoneRef = useRef();
    const userNameRef = useRef();
    const aboutMeRef = useRef();
    const ageRef = useRef();

  
    //instantiate storage
    const storage = getStorage();

    const db = getFirestore(app);
    let navigate = useNavigate();

    
    const [lat, setLat] = useState(0); // New state for latitude
    const [lng, setLng] = useState(0); // New state for longitude
    
    // Function to handle address selection
    const handleFromSelect = async (address) => {
        setFromAddress(address); // Update displayed location with selected address
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);
            setFromLocation(latLng);
            setLat(latLng.lat); // Update lat state
            setLng(latLng.lng); // Update lng state
            console.log("Latitude: ", latLng.lat, "Longitude: ", latLng.lng); // Debugging output
        } catch (error) {
            console.error('Error getting location:', error);
        }
    }; 

    // UseEffect to handle changes in fromLocation
    useEffect(() => {
        console.log("Updated Latitude: ", fromLocation.lat);
        console.log("Updated Longitude: ", fromLocation.lng);
        
    }, [fromLocation]);
    

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
            const userQuery = query(collection(db, "users"), where("userId", "==", uid));
                
                // Fetch user data from Firestore
                getDocs(userQuery).then((querySnapshot) => {
                    querySnapshot.forEach((docSnapshot) => {
                        const userData = docSnapshot.data();
                        setloggedInUser(userData.fullname);
                        setviewPhone(userData.phone);
                        setuserLocation(userData.userLocation); 
                        setaboutMe(userData.aboutMe)
                        setAgeInfo(userData.age)
                        
                        // Check for profile and cover photos in Firebase
                        if (userData.profilePic) {
                            setProfilePicture(userData.profilePic);
                        }
                        if (userData.coverPhoto) {
                            setCoverPicture(userData.coverPhoto);
                        }
                    });
                });

            //update profile picture 
            window.saveProfilePhoto = function(){
                const profilePhoto = fileInput.current.files[0];   

                const storageRef = ref(storage, 'images/' + Math.random() + profilePhoto.name);
                const uploadTask = uploadBytesResumable(storageRef, profilePhoto);

                uploadTask.on('state_changed', 
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        setShowAlert(true);              
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const roundedOffProgress = Math.round(progress);

                        setuploadProfileStatus(roundedOffProgress);
                        setuploadStatStatus('Upload is ' + roundedOffProgress + '% done');
                        setUploadBtn("Uploading.."+roundedOffProgress +'%')
                        
                    }, 
                (error) => {
                    // Handle unsuccessful uploads
                }, 
                () => {
                    // Handle successful uploads on complete
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                        updateDoc(doc(db, "users", user.uid), {
                            profilePic: downloadURL

                        }).then(()=>{
                            swal("Success", "Profile picture updated successfully!", "success").then((value) => {
                                window.location.reload(false);
                                });
                            //
                        })  
                    });
                }
                );
            }
            //end profile picture

            //update Cover photo
            window.saveCoverPhoto = function(){

                const coverPhoto = coverInput.current.files[0];   

                const storageRef = ref(storage, 'images/' + Math.random() + coverPhoto.name);
                const uploadTask = uploadBytesResumable(storageRef, coverPhoto);

                uploadTask.on('state_changed', 
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        setShowAlertTwo(true);              
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const roundedOffProgress = Math.round(progress);

                        setuploadProfileStatus(roundedOffProgress);
                        setuploadStatStatus2('Upload is ' + roundedOffProgress + '% done');
                        setUploadBtn2("Uploading.."+roundedOffProgress +'%')
                        
                    }, 
                (error) => {
                    // Handle unsuccessful uploads
                }, 
                () => {
                    // Handle successful uploads on complete
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                        updateDoc(doc(db, "users", user.uid), {
                            coverPhoto: downloadURL

                        }).then(()=>{
                            swal("Success", "Cover photo updated successfully!", "success").then((value) => {
                                window.location.reload(false);
                                });
                            //
                        })  
                    });
                }
                );

            }

            window.goToNext = function(){

                const userQuery = query(collection(db, "users"), where("userId", "==", uid));
                
                // Fetch user data from Firestore
                getDocs(userQuery).then((querySnapshot) => {
                    querySnapshot.forEach((docSnapshot) => {
                        const userData = docSnapshot.data();
                        
                        // Check for profile and cover photos in Firebase
                        if (userData.profilePic && userData.coverPhoto && userData.userLocation && userData.fullname) {
                            navigate('/services-set-up');
                        }else{
                            swal("Error", "Please update all info before proceeding!", "info")
                        }

                    });
                });

            }

            ////
        } else {
            navigate("/");  
        }
    });



    function updateProfilePhoto(){
        const profilePhoto = fileInput.current.files[0];
        const url = URL.createObjectURL(profilePhoto);
        setProfilePicture(url)
    }

    //update profile picture on select
    function updateCoverPhoto(){
        const profilePhoto = coverInput.current.files[0];
        const url = URL.createObjectURL(profilePhoto);
        setCoverPicture(url)
    }

    const [theUser, setTheUser] = useState();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setTheUser(user.uid);
            } else {
                setTheUser(null); // Handle the case where the user is logged out
            }
        });
    
        return () => unsubscribe(); // Cleanup on unmount
    }, []); // Empty dependency array to run only once (when the component mounts)
    
    function saveChanges(){

        const theFullName =  userNameRef.current.value;
        const thePhoneNumber = phoneRef.current.value;
        const aboutMeInfo = aboutMeRef.current.value;
        const age = ageRef.current.value;

        const ageInt = parseInt(age);

        if(ageInt < 18){
            swal("Error", "You must be 18 and above to sign up as a massage therapist! Try again a few years.", "error")
        }else{
            if (fromAddress != ''){
                setShowProgress(true);
                setShowSignInbtn(false); 

                console.log("Final Latitude for Firestore: ", lat);
               console.log("Final Longitude for Firestore: ", lng); 

                updateDoc(doc(db, "users", theUser), {

                    fullname: theFullName,
                    phone:thePhoneNumber,
                    userLocation: fromAddress,
                    aboutMe:aboutMeInfo,
                    age:ageInt,
                    lat:fromLocation.lat,
                    lng:fromLocation.lng

                }).then(()=>{
                    setShowProgress(false);
                    setShowSignInbtn(true);
                    swal("Success", "Info updated Successfully!", "success")
                    // .then((value) => {
                    //     window.location.reload(false);
                    //     });
                    //
                })  

            }else{
                swal("Error", "Kindly select a location from the search box", "error")
            }
        
        }

    }



    return (
        <div className='accSetMain'>
            <div className='accSet1'>
                <h2>Set Up your Account</h2>

                <div className='accSetupProfCont clearfix'>
                    <div className='accSetupCover'>
                        <img src={userCoverPicture} alt="" />
                    </div>
                    <div className='accSetupProfile'>
                        <img src={userProfilePicture} alt="" />
                    </div>
                    <div className='accSetupTexts'>
                        <h3>{loggedInUser}</h3>
                        <p>{theuserLocation}</p>  {/* Display default or selected location */}
                    </div>
                </div>

                <div className='profBottomCover2'>

                    <Form.Group className='mb-3' controlId='formBasicAboutMe'>
                        <Form.Label>About Me</Form.Label>
                        <Form.Control 
                            as='textarea' 
                            placeholder='A brief description about yourself' 
                            defaultValue={aboutMe} 
                            ref={aboutMeRef} 
                        /> 
                    </Form.Group>


                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Name</Form.Label> 
                        <Form.Control type='text' placeholder='Enter name' defaultValue={loggedInUser} ref={userNameRef}/> 
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Age</Form.Label> 
                        <Form.Control type='number' min="18" max="50" placeholder='Enter name' defaultValue={ageInfo} ref={ageRef}/> 
                    </Form.Group>


                    <div className='alert alert-primary'>
                        <p className='alertPr'>Select the location that best represents where you're based. Your profile will be shown to users searching nearby this area.</p>
                    </div> 

                    <Form.Group className="mb-4" controlId="fromLocation">
                        <Form.Label>Search for a location</Form.Label>
                        <PlacesAutocomplete value={fromAddress} onChange={setFromAddress} onSelect={handleFromSelect}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <Form.Control {...getInputProps({ placeholder: 'Select a location' })} />
                                    <div className='pickupDivsc'>
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => (
                                            <div key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                                                {suggestion.description}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </Form.Group> 

                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type='text' placeholder='Enter Phone' defaultValue={viewPhone}  ref={phoneRef}/>
                    </Form.Group>

                    {showSignInbtn && (
                        <Button variant='primary' type='submit' className='saveChgsBtn' onClick={saveChanges}>
                            Save Changes
                        </Button>
                    )} 

                    {showProgress && ( 
                        <Button variant='dark' disabled className='saveChgsBtn'>
                            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                            <span> Updating....</span>
                        </Button>
                    )}

                    <br /> <br /> 

                    <div className='alert alert-warning'>
                        <p className='alertPr'>Save the changes above before proceeding to the next page</p>
                    </div> 

                </div>

                <hr />

                <div className='EditProfileBody'> 
                <h3>Update Profile & Cover Photos</h3>  
                <br />

                <p>Profile Picture</p>
                <div className='d-flex'>         
                    <input type="file" ref={fileInput} className="form-control" onChange={updateProfilePhoto}/>             
                    <button onClick={window.saveProfilePhoto}>{UploadBtn}</button>
                </div>
                <br />

                {showAlert && 
                    <Alert>
                        {uploadStatStatus}
                        <ProgressBar striped variant="info" now={uploadProfileStatus} />
                    </Alert>
                }

                <p>Cover Photo</p>
                <div className='d-flex'>         
                    <input type="file" ref={coverInput} className="form-control" onChange={updateCoverPhoto}/>             
                    <button onClick={window.saveCoverPhoto}>{UploadBtn2}</button>
                </div>
                
                <br />

                {showAlertTwo && 
                    <Alert>
                        {uploadStatStatus2}
                        <ProgressBar striped variant="info" now={uploadProfileStatus} />
                    </Alert>
                }



                <button className='btnLogin' onClick={window.goToNext}>
                    Next  
                </button>

                </div>
            </div>
            <div className='accSet2'>
                <div className='theOpacity'></div>
                <div className='theOpacitySetup'>
                    <h2>Access Thousands of Clients Nearby!</h2>
                    <p>Join our community of massage therapists and connect with clients actively seeking your services. Whether you're offering incall or outcall options, our platform helps you grow your business and reach more people in your area. Start your journey to success today</p>
                </div>
            </div>
        </div>
    );
}

export default AccountSetuPul;
 