import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { faClose, faSearch} from '@fortawesome/free-solid-svg-icons'
import { faSpa, faDumbbell, faRunning, faLeaf, faCheckCircle, faHotTub, faHands, faUserInjured, faBaby, faWater, faBullseye, faHandHoldingHeart, faBrain, faFlask, faSeedling, faChair, faHandsHelping, faRainbow, faOm, faRulerCombined, faHeart, faYinYang, faStar, faShoePrints, faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, getDocs, where, onSnapshot,setDoc,addDoc, orderBy } from "@firebase/firestore"; 
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { app } from '../firebase.js';
import ProgressBar from 'react-bootstrap/ProgressBar'; 
import NavBar from '../Components/NavBar'
import NavBarAccount from '../Components/NavBarAccount.js';
import Form from 'react-bootstrap/Form'; 
import Footer from '../Components/Footer.js';
import Spinner from 'react-bootstrap/Spinner'; // Import the spinner 


function Home() {

    const [fromLocation, setFromLocation] = useState({ lat: 0, lng: 0 });
    const [fromAddress, setFromAddress] = useState('');  // Default location
    const [showGenderOptions, setShowGenderOptions] = useState(false);
    const [showPriceRange, setShowPriceRange] = useState(false);
    const [showAgeRange, setShowAgeRange] = useState(false)
    const [searchResults, setSearchResults] = useState([]); // State to store search results
    const [selectedGender, setSelectedGender] = useState('Select gender'); // New state for selected gender

    const handleGenderSelect = (gender) => {
        setSelectedGender(gender); // Update selected gender
        setShowGenderOptions(false); // Close the dropdown after selection
    };

    const [minAge, setMinAge] = useState(18); // State for minimum age
    const [maxAge, setMaxAge] = useState(50); // State for maximum age
    const [minPrice, setMinPrice] = useState(1000); // State for minimum price
    const [maxPrice, setMaxPrice] = useState(90000); // State for maximum price 
    const [showSearchResults, setShowSearchResults] = useState(false); // Control search results visibility
    const [showAccountSection, setShowAccountSection] = useState(true); // Control account section visibility
    const [loading, setLoading] = useState(false); // Loading state 

    const [isVisible, setIsVisible] = useState(false);


    const toggleModal = () => {
        setIsVisible(!isVisible);
    };



    const handleFromSelect = async (address) => {
        setFromAddress(address);  // Update displayed location with selected address
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);
            setFromLocation(latLng);
        } catch (error) {
            console.error('Error', error);
        }
    };

    function geocodeAddress(address) {
        const apiKey = 'AIzaSyA297W_3urY4B_E0SbyfM_C_eqy6a6JGrY';
        return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`)
          .then(response => response.json())
          .then(data => {
            if (data.status === 'OK') {
              const location = data.results[0].geometry.location;
              return { lat: location.lat, lng: location.lng };
            } else {
              throw new Error('Geocoding failed: ' + data.status);
            }
        });
    }

    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of Earth in kilometers
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
                  Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers
        return distance;
    }
      
      

    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false); // To track user login status
    useEffect(() => {
        // Check user login status when the component is mounted
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is logged in
                setIsLoggedIn(true);
            } else {
                // User is logged out
                setIsLoggedIn(false);
            }
        });
    }, [auth]);




    const services = [
        { name: 'All Services', icon: faSpa },
        { name: 'Thai Massage', icon: faHands },
        { name: 'Couples Massage', icon: faHeart },
        { name: 'Hot Stone', icon: faHotTub },
        
        
        { name: 'Balinese Massage', icon: faUmbrellaBeach },
        { name: 'Prenatal Massage', icon: faBaby },
        { name: 'Foot Massage', icon: faShoePrints },
        
        { name: 'Chair Massage', icon: faChair },
        { name: 'Reflexology', icon: faShoePrints },
        { name: 'Rolfing', icon: faRulerCombined },
        
        { name: 'Swedish', icon: faSpa },

        { name: 'Shiatsu Massage', icon: faUserInjured },
        { name: 'Tui Na Massage', icon: faHandsHelping },
        { name: 'Sports Massage', icon: faRunning },
        { name: 'Cupping Therapy', icon: faFlask },
        { name: 'Deep Tissue', icon: faDumbbell },
        { name: 'Lomi Lomi', icon: faRainbow },
        { name: 'Rejuvenation', icon: faStar },
        { name: 'Aromatherapy', icon: faLeaf },
        { name: 'Ayurvedic', icon: faOm },
        { name: 'Trigger Point', icon: faBullseye },
        { name: 'Craniosacral', icon: faBrain },
        { name: 'Myofascial', icon: faHandHoldingHeart },
        { name: 'Bamboo', icon: faSeedling },
        { name: 'Acupressure', icon: faYinYang },
        { name: 'Lymphatic', icon: faWater }
        
    ];

    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app); 

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true); // Start loading
          try {
            const q = query(
              collection(db, 'users'),
              where("isuserVerified", "==", "yes") // Fetch only verified users
            );
            const querySnapshot = await getDocs(q);
            let activeUsers = [];
            let inactiveUsers = [];
      
            querySnapshot.forEach((doc) => {
              const userData = doc.data();
              const expiryDate = new Date(userData.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date
              const isPlanActive = expiryDate > new Date(); // Check if the plan is still active
      
              // Check if the user's plan is one of the valid types
              const validPlanTypes = ['Standard Plan', 'Premium Plan'];
              const hasValidPlanType = validPlanTypes.includes(userData.planType);
      
              // Separate users based on whether their plan is active
              if (isPlanActive && hasValidPlanType) {
                activeUsers.push({ id: doc.id, ...userData }); // Push to active users list
              } else {
                inactiveUsers.push({ id: doc.id, ...userData }); // Push to inactive users list
              }
            });
      
            // Combine active users first, followed by inactive users
            setStudentList([...activeUsers, ...inactiveUsers]);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          setLoading(false); // Stop loading
        };
      
        fetchData();
      }, [db]);
        

    //check if user is logged in here

    const handleSearch = async () => {
        setLoading(true); // Start loading when search starts
        try {
            if (fromAddress !== '') {
                setIsVisible(false);
                // Geocode the address to get lat/lng of the searched location
                const searchedLocation = await geocodeAddress(fromAddress);
        
                let q = collection(db, 'users'); // Start with the users collection
        
                // Apply filters based on search criteria (gender, age, price)
                if (selectedGender !== 'Select gender') {
                    q = query(q, where("gender", "==", selectedGender));
                }
                q = query(q, where("age", ">=", minAge), where("age", "<=", maxAge));
                q = query(q, where("minPrice", ">=", minPrice), where("minPrice", "<=", maxPrice));
        
                // Fetch all users matching the filters
                const querySnapshot = await getDocs(q);
                let activeUsers = [];
                let inactiveUsers = [];
        
                querySnapshot.forEach(doc => {
                    const userData = doc.data();
        
                    // Calculate the distance between the user's location and the searched location
                    const distance = calculateDistance(
                        searchedLocation.lat,
                        searchedLocation.lng,
                        userData.lat, // User's latitude
                        userData.lng  // User's longitude
                    );
        
                    // Filter users within a 10 km radius
                    if (distance <= 10) {
                        // Check if the plan is active
                        const expiryDate = new Date(userData.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date
                        const isPlanActive = expiryDate > new Date(); // Check if the plan is still active
                        const validPlanTypes = ['Standard Plan', 'Premium Plan']; // Define valid plan types
                        const hasValidPlanType = validPlanTypes.includes(userData.planType);
    
                        // Separate users into active and inactive plan lists
                        if (isPlanActive && hasValidPlanType) {
                            activeUsers.push({ id: doc.id, ...userData });
                        } else {
                            inactiveUsers.push({ id: doc.id, ...userData });
                        }
                    }
                });
        
                // Combine active users first, then inactive users
                setSearchResults([...activeUsers, ...inactiveUsers]);
                setShowSearchResults(true); // Show the search results section
                setShowAccountSection(false); // Hide the account section
            } else {
                swal('Error!', 'Please search for and select a location where you want to find nearby massage therapists.', 'info');
            }
        
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
        setLoading(false); // Stop loading after search completes
    };
    


    // State to keep track of the currently selected service
    const [selectedService, setSelectedService] = useState(0); // Default to the first service
    const handleClick = (index) => {
        console.log(`Selected Service: ${services[index].name}`);
        setSelectedService(index); // Update selected service when clicked
        if(services[index].name === "All Services"){
            window.location.reload(); 
        }
    };

    console.log(selectedService)
      

  return (

    <div className=''>
        <div>
            {/* If the user is logged in, show NavBarAccount, otherwise show NavBar */}
            {isLoggedIn ? <NavBarAccount /> : <NavBar />}
        </div>

        <section className='searchBoxMobile' onClick={toggleModal}>
            <FontAwesomeIcon icon={faSearch} className="searchIconMobileView"/>
            <div>
                <h6>Where are you?</h6>
                <p>Select a location . Gender . Age . Price Range</p>
            </div>
        </section>

        <div className={`mobileSearchBox ${isVisible ? 'visible' : 'hidden'}`}>
            <div className='mobileSearchBoxClose' onClick={toggleModal} >
                <FontAwesomeIcon icon={faClose} />
            </div>

            <div>
                <label htmlFor="">Where are you?</label>
                {/* <input type="text" placeholder='Search locations'/> */}
                <PlacesAutocomplete value={fromAddress} onChange={setFromAddress} onSelect={handleFromSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <Form.Control {...getInputProps({ placeholder: 'Select a location' })} className='locationFormInput2'/>
                                <div className='pickupDivsc22' >
                                    {loading && <div>Loading...</div>} 
                                    {suggestions.map((suggestion) => (
                                        <div key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                                            {suggestion.description}
                                        </div>
                                    ))}
                                </div>
                            </div> 
                        )}
                    </PlacesAutocomplete>
            </div>

            <div className='genderSearch2' onClick={() => setShowGenderOptions(!showGenderOptions)}>
                <h6 className='searchTitle'>Gender</h6>
                <p className='searchP'>{selectedGender}</p> {/* Display selected gender */}

                {showGenderOptions && (
                    <div className='genderModalSearch'>
                    <p onClick={() => handleGenderSelect('Female')}>Female</p>
                    <p onClick={() => handleGenderSelect('Male')}>Male</p>
                    <p onClick={() => handleGenderSelect('Non Binary')}>Non Binary</p>
                    </div>
                )}
            </div>

            <div className='ageSearch2' onClick={() => setShowAgeRange(!showAgeRange)}>
                <h6 className='searchTitle'>Age</h6>
                <p className='searchP'>{`${minAge} - ${maxAge}`}</p> {/* Display selected age range */}

                {showAgeRange && (
                <div className='genderModalSearch'>
                    {/* Minimum Age Slider */}
                    <label htmlFor="minAge">Min Age: {minAge}</label>
                    <input
                    type='range'
                    min='18'
                    max='50'
                    value={minAge}
                    onChange={(e) => {
                        const newMinAge = Number(e.target.value);
                        // Ensure max age is at least the new min age
                        if (newMinAge <= maxAge) {
                        setMinAge(newMinAge);
                        }
                    }}
                    className='ageRangeInput'
                    />
                    <br />

                    {/* Maximum Age Slider */}
                    <label htmlFor="maxAge">Max Age: {maxAge}</label>
                    <input
                    type='range'
                    min={minAge}
                    max='50'
                    value={maxAge}
                    onChange={(e) => {
                        const newMaxAge = Number(e.target.value);
                        // Ensure min age is at most the new max age
                        if (newMaxAge >= minAge) {
                        setMaxAge(newMaxAge);
                        }
                    }}
                    className='ageRangeInput'
                    />
                </div>
                )} 
            </div>

            <div className='genderSearch2' onClick={() => setShowPriceRange(!showPriceRange)}>
                <h6 className='searchTitle'>Minimum Price</h6>
                <p className='searchP'>{`KES.${minPrice} - KES.${maxPrice}`}</p> {/* Display selected price range */}

                {showPriceRange && (
                <div className='genderModalSearch'>
                {/* Minimum Price Slider */}
                <label htmlFor="minPrice">Min Price: KES.{minPrice}</label>
                <input
                    type='range'
                    min='1000'
                    max='100000'
                    value={minPrice}
                    onChange={(e) => setMinPrice(Number(e.target.value))}
                    className='priceRangeInput'
                /> 
                <br />

                {/* Maximum Price Slider */}
                <label htmlFor="maxPrice">Max Price: KES.{maxPrice}</label>
                <input
                    type='range'
                    min={minPrice}
                    max='100000'
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(Number(e.target.value))}
                    className='priceRangeInput'
                />
                </div>
                )}
            </div>

            <button onClick={handleSearch} className='mobileSearchButton'>Search</button>

        </div>

        <section className='querySection'>       
            
        <div className='searchBox'>
            <div className='locationSearch'>
                <label htmlFor="">Where are you?</label>
                {/* <input type="text" placeholder='Search locations'/> */}
                <PlacesAutocomplete value={fromAddress} onChange={setFromAddress} onSelect={handleFromSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <Form.Control {...getInputProps({ placeholder: 'Select a location' })} className='locationFormInput'/>
                                <div className='pickupDivsc2' >
                                    {loading && <div>Loading...</div>} 
                                    {suggestions.map((suggestion) => (
                                        <div key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                                            {suggestion.description}
                                        </div>
                                    ))}
                                </div>
                            </div> 
                        )}
                    </PlacesAutocomplete>
            </div> 
            <span className='span11'>|</span>
            <div className='genderSearch' onClick={() => setShowGenderOptions(!showGenderOptions)}>
                    <h6 className='searchTitle'>Gender</h6>
                    <p className='searchP'>{selectedGender}</p> {/* Display selected gender */}

                    {showGenderOptions && (
                        <div className='genderModalSearch'>
                        <p onClick={() => handleGenderSelect('Female')}>Female</p>
                        <p onClick={() => handleGenderSelect('Male')}>Male</p>
                        <p onClick={() => handleGenderSelect('Non Binary')}>Non Binary</p>
                        </div>
                    )}
            </div>
            <span className='span13'>|</span>
 
            <div className='ageSearch' onClick={() => setShowAgeRange(!showAgeRange)}>
                <h6 className='searchTitle'>Age</h6>
                <p className='searchP'>{`${minAge} - ${maxAge}`}</p> {/* Display selected age range */}

                {showAgeRange && (
          <div className='genderModalSearch'>
            {/* Minimum Age Slider */}
            <label htmlFor="minAge">Min Age: {minAge}</label>
            <input
              type='range'
              min='18'
              max='50'
              value={minAge}
              onChange={(e) => {
                const newMinAge = Number(e.target.value);
                // Ensure max age is at least the new min age
                if (newMinAge <= maxAge) {
                  setMinAge(newMinAge);
                }
              }}
              className='ageRangeInput'
            />
            <br />

            {/* Maximum Age Slider */}
            <label htmlFor="maxAge">Max Age: {maxAge}</label>
            <input
              type='range'
              min={minAge}
              max='50'
              value={maxAge}
              onChange={(e) => {
                const newMaxAge = Number(e.target.value);
                // Ensure min age is at most the new max age
                if (newMaxAge >= minAge) {
                  setMaxAge(newMaxAge);
                }
              }}
              className='ageRangeInput'
            />
          </div>
        )} 
            </div>

            <div className='priceRangeSearch' >
            <span className='span14'>|</span>
                <div className='' onClick={() => setShowPriceRange(!showPriceRange)}>
                    <h6 className='searchTitle'>Minimum Price</h6>
                    <p className='searchP'>{`KES.${minPrice} - KES.${maxPrice}`}</p> {/* Display selected price range */}


                    {showPriceRange && (
                    <div className='genderModalSearch'>
                    {/* Minimum Price Slider */}
                    <label htmlFor="minPrice">Min Price: KES.{minPrice}</label>
                    <input
                        type='range'
                        min='1000'
                        max='100000'
                        value={minPrice}
                        onChange={(e) => setMinPrice(Number(e.target.value))}
                        className='priceRangeInput'
                    /> 
                    <br />

                    {/* Maximum Price Slider */}
                    <label htmlFor="maxPrice">Max Price: KES.{maxPrice}</label>
                    <input
                        type='range'
                        min={minPrice}
                        max='100000'
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(Number(e.target.value))}
                        className='priceRangeInput'
                    />
                    </div>
                )}
                </div>
                <button className='searchSubmitBtn' onClick={handleSearch}>
                    <FontAwesomeIcon icon={faSearch} className="searchIcon"/>
                </button>
            </div>
        </div>
        </section>

   

        <section className="servicesSection">
        <div className="massage-types-container">
            {services.map((service, index) => (
            <div
                key={index}
                className={`massage-type ${selectedService === index ? 'selected' : ''}`} // Apply selected class if the service is selected
                onClick={() => handleClick(index)} // Set selected service on click
            >
                <FontAwesomeIcon icon={service.icon} className="icon" />
                <p>{service.name}</p>
            </div>
            ))}
        </div>
        </section>

        {loading ? (
    <div className="loading-spinner">
        {/* You can use a CSS spinner or an actual component */}
        <p>Loading...</p>
    </div>
) : (
    <>
{showSearchResults && (
  <section className='searchResults'>
    <h3>Search Results</h3>
    <div className="accountSection">
      {searchResults.length > 0 ? (
        searchResults.map((result, index) => {
          // Check if the user's plan is active and valid
          const expiryDate = new Date(result.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date
          const isUserPlanActive = expiryDate > new Date(); // Check if plan is still active
          const userPlanType = result.planType;

          return (
            <div key={index} className='servicePoviderListing'>
              <Link to={"view-user?" + result.userId} className='serviceProvLink'>
                <div className='serviceProvImageCont'>
                  <img src={result.profilePic} alt="" />
                </div>
                <h6>
                  {result.fullname}
                  {/* Conditionally render the verified icon based on the user's plan type and status */}
                  {(isUserPlanActive && (userPlanType === 'Standard Plan' || userPlanType === 'Premium Plan')) && (
                    <FontAwesomeIcon icon={faCheckCircle} className="verifiedBtn" />
                  )}
                </h6>
                <p>{result.userLocation}</p>
                <p>From <span>KES. {result.minPrice}</span></p>
              </Link>
            </div>
          );
        })
      ) : (
        <p>No results found.</p>
      )}
    </div>
  </section>
)}


{showAccountSection && (
  <section className='accountSection'>
    {studentsList.map((mystudent) => {
      // Check if the student's plan is active and valid
      const expiryDate = new Date(mystudent.expiryTime.seconds * 1000); // Convert Firestore timestamp to Date
      const isStudentPlanActive = expiryDate > new Date(); // Check if plan is still active
      const studentPlanType = mystudent.planType;

      return (
        <div key={mystudent.userId} className='servicePoviderListing'>
          <Link to={"view-user?" + mystudent.userId} className='serviceProvLink'>
            <div className='serviceProvImageCont'>
              <img src={mystudent.profilePic} alt="" />
            </div>
            <h6>
              {mystudent.fullname}
              {/* Conditionally render the verified icon based on the student's plan type and status */}
              {(isStudentPlanActive && (studentPlanType === 'Standard Plan' || studentPlanType === 'Premium Plan')) && (
                <FontAwesomeIcon icon={faCheckCircle} className="verifiedBtn" />
              )}
            </h6>
            <p>{mystudent.userLocation}</p>
            <p>From <span>KES. {mystudent.minPrice}</span></p>
          </Link>
        </div>
      );
    })}
  </section>
)}

    </>
)}

        <Footer/>

    </div>
  )
}

export default Home